export const required = (value) => !!value || 'Pole jest wymagane'

export const password = (value) => {
  const re = /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/
  return re.test(value) || 'Hasło jest niepoprawne';
}

export const email = (value) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value) || 'Niepoprawny adres email';
}

export const maxFileSize = (value, mb) => {
  return value.size < mb * 1000000 || `Plik nie może być większy niż ${mb}mb`
}
